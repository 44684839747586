<template>
    <div class="container">
        <img src="../../assets/imgs/banner_shdt.png" alt="" class="banner" />
        <div class="checkMain">
            <img src="../../assets/imgs/checkIng.png" alt="" class="checkImg">
            <p class="word1">红河州湖南商会会员申请验证中</p>
            <p>结果将在1-2个工作日完成<br/>敬请留意短信或公众号私信</p>
            <button class="checkBtn" @click="goIndex">确定</button>
        </div>

    </div>
  
</template>

<script>
export default {
    name: "checkIng",
    methods: {
        goIndex(){
            this.$router.push("/")
        }
    }
}
</script>

<style scoped>
.container{
    background: #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.banner{
    background: #2E2E2E;
    height: 315px;
    width: 100%;
}

.checkMain{
    margin-top: 20px;
    width: 1200px;
    background: white;
    color: #999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkImg{
    margin: 100px 0 45px 0;
    width: 150px; 
}

.word1{
    color: #D05550;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
}

.checkBtn{
    margin-top: 45px;
    margin-bottom: 100px;
    border: none;
    outline: none;
    background: #CE251F;
    border-radius: 7px;
    width: 232px;
    height: 50px;
    color: white;
    font-size: 17px;
}
</style>