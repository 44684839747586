<template>
    <div class="container">
        <img src="../../assets/imgs/banner_shdt.png" alt="" class="banner" />
        <div class="checkMain">
            <img src="../../assets/imgs/checkWrong.png" alt="" class="checkImg">
            <p class="word1">对不起本次申请审核未通过</p>
            <p>对不起您的信息，不符合标椎</p>
            <button class="checkBtn">确定</button>
        </div>

    </div>
  
</template>

<script>
export default {
    name: "checkFalse",
}
</script>

<style scoped>
.container{
    background: #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.banner{
    background: #2E2E2E;
    height: 315px;
    width: 100%;
}

.checkMain{
    width: 1200px;
    background: white;
    color: #999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkImg{
    margin: 100px 0 45px 0;
    width: 150px; 
}

.word1{
    color: #D05550;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
}

.checkBtn{
    margin-top: 45px;
    margin-bottom: 100px;
    border: none;
    outline: none;
    background: #CE251F;
    border-radius: 7px;
    width: 232px;
    height: 50px;
    color: white;
    font-size: 17px;
}
</style>