<template>
  <div class="indexContanier">
    <!-- 轮播图 -->
    <el-carousel height="800px" :interval="4000">
      <el-carousel-item v-for="(item, index) in imagesBox" :key="index">
        <img :src="item.idView" alt="" style="position: relative;" class="lbImg">
        <div class="imgTxt">
          <p>大力宣传滇湘两地投资环境</p>
          <p class="p2">实现资讯、资源共享，促进交流合作</p>
          <router-link to="Shjs" class="ljgd">了解更多>></router-link>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="navTip">
      <div class="tip1" style="width: 400px">
        <p class="tip1_top">坚定发展信心·弘扬湘商精神</p>
        <p>
          <span class="tipHx">---------------</span>红河哈尼族彝族自治州湖南商会
        </p>
      </div>
      <div class="tip1" style="width: 290px">
        <div class="cont">
          <img src="../../assets/imgs/phone2.png" alt="" class="contIcon1" />
          <div class="ctxt">
            <p class="tip_p">联系方式：</p>
            <p style="font-weight: bold; font-size: 19px" class="tip_p">
              15012163225
            </p>
          </div>
        </div>
      </div>
      <div class="tip1" style="width: 150px">
        <div class="cont1">
          <img src="../../assets/imgs/address.png" alt="" class="contIcon2" />
          <router-link to="Lxwm" class="shdzLink">商会地址</router-link>
        </div>
      </div>
      <div class="tip1" style="width: 150px">
        <div class="cont1">
          <img src="../../assets/imgs/sq.png" alt="" class="contIcon2" />
          <router-link to="Rhsq" class="shdzLink">会员申请</router-link>
        </div>
      </div>
      <div class="tip1" style="width: 150px">
        <div class="cont1">
          <img src="../../assets/imgs/search.png" alt="" class="contIcon2" />
          <router-link to="Hyfc" class="shdzLink">查询会员单位</router-link>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="aboutUs">
      <div class="aboutCont">
        <div class="about1">
          <img src="../../assets/imgs/gywm.png" alt="" class="aboutImg" />
          <div class="about1Txt">
            <p style="font-size: 30px; font-weight: 300">ABOUT&nbsp;SANSON</p>
            <p style="color: #333333; font-size: 30px">关于我们</p>
            <p class="js">
              红河州湖南商会于2016年9月19日成立，是由在滇从事合法生产、经营的湘籍人士(企业)组成的非营利性、联合性社会团体，是受红河哈尼族彝族自治州政府部门直接领导的州级商会。商会以团结、服务会员，沟通与联系政府，促进湘滇两省积极发展为宗旨。
            </p>
            <router-link to="Shjs" class="ckLink">查看详情</router-link>
          </div>
        </div>
        <ul class="about2">
          <li class="a2_li" style="border: none">
            <div class="liTxt0" style="color: #ce261f">
              2016 <span style="font-size: 13px">年</span>
            </div>
            <p class="liTxt" style="color: #767676">成立年限</p>
          </li>
          <li class="a2_li">
            <div class="liTxt0">
              2000 <span style="font-size: 13px">+</span>
            </div>
            <p class="liTxt">拥有会员</p>
          </li>
          <li class="a2_li">
            <div class="liTxt0">100 <span style="font-size: 13px">+</span></div>
            <p class="liTxt">会员商家</p>
          </li>
          <li class="a2_li">
            <div class="liTxt0">100 <span style="font-size: 13px">+</span></div>
            <p class="liTxt">设计领域</p>
          </li>
          <li class="a2_li">
            <div class="liTxt0">100 <span style="font-size: 13px">+</span></div>
            <p class="liTxt">行业好评</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 商会新闻 -->
    <div class="news_box">
      <div class="grid-content news1">
        <div class="nTitle">
          <p class="nt1">NEWS</p>
          <p class="nt2">商会新闻</p>
        </div>
        <ul>
          <li class="xwxq" v-for="(item, index) in NewsList" :key="index">
            <img
              :src="'http://hhzhnsh.xp.yn.cn/' + item.cover_image"
              alt=""
              class="xwxqImg"
            />
            <div class="xwxqTxt">
              <div class="visitTitle" @click="NewsRouter(item.id)">
                {{ item.title }}
              </div>
              <div class="article">
                {{ Texttransfer(item.content) }}
              </div>
              <div class="keys" id="keys1">
                <p class="tag">商会新闻</p>
                <p>日期：{{ item.time }}</p>
                <p>点击：{{ item.clicks }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 热点文章 -->
      <div class="grid-content news2">
        <div class="nTitle">
          <p class="nt1">HOT&nbsp;ARTICLE</p>
          <p class="nt2">热点文章</p>
        </div>
        <ul>
          <li class="hotLi" v-for="(item, index) in HotList" :key="index">
            <div class="date_box">
              <p style="font-size: 35px">{{ TimeDay(item.time) }}</p>
              <p>{{ TimeYM(item.time) }}</p>
            </div>
            <div class="hotTxt">
              <div class="hotLink" @click="HotRouter(item.id, item.catname)">
                {{ item.title }}
              </div>
              <div class="keys" id="keys2">
                <p>日期：{{ item.time }}</p>
                <p>点击：{{ item.clicks }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 商会成员
      <div class="grid-content news3" style="margin-left: 20px">
        <div class="nTitle">
          <p class="nt1">MEMBER</p>
          <p class="nt2">商会班子成员</p>
        </div>
        <ul style="margin-top: 20px">
          <li class="memberLi">
            <p class="position">会长：</p>
            <p>余周周</p>
          </li>
          <li class="memberLi">
            <p class="position">监事长：</p>
            <p>周周</p>
          </li>
          <li class="memberLi">
            <p class="position">秘书长：</p>
            <p>周周</p>
          </li>
          <li class="memberLi">
            <p class="position">副会长：</p>
            周周&nbsp;周周&nbsp;周周&nbsp;周周&nbsp;周周&nbsp;周周&nbsp;周周&nbsp;
          </li>
        </ul>
      </div>-->
    </div>
    <!-- 人物专访 -->
    <!-- <div class="visit_box">
      <div class="nTitle" style="margin-left: 100px; margin-bottom: 20px">
        <p class="nt1">VISIT</p>
        <p class="nt2">人物专访</p>
      </div>

      <div id="demo">
        <ul class="visit_ul" id="demo1">
          <li class="visit_li">
            <img src="../../assets/imgs/visit.png" alt="" class="visitImg" />
            <div class="visitTxt">
              <router-link class="visitTitle" to="Rwzf"
                >谈宋——男儿志坚写传奇，山泉润水暖桑</router-link
              >
              <div class="vTxt">
                男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑
              </div>
              <router-link class="moreLink" id="link1" to="Rwzf"
                >more<span style="color: #999999">+</span></router-link
              >
            </div>
          </li>
          <li class="visit_li">
            <img src="../../assets/imgs/visit.png" alt="" class="visitImg" />
            <div class="visitTxt">
              <router-link class="visitTitle" to="Rwzf"
                >谈宋——男儿志坚写传奇，山泉润水暖桑</router-link
              >
              <div class="vTxt">
                男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑
              </div>
              <router-link class="moreLink" id="link1" to="Rwzf"
                >more<span style="color: #999999">+</span></router-link
              >
            </div>
          </li>
          <li class="visit_li">
            <img src="../../assets/imgs/visit.png" alt="" class="visitImg" />
            <div class="visitTxt">
              <router-link class="visitTitle" to="Rwzf"
                >谈宋——男儿志坚写传奇，山泉润水暖桑</router-link
              >
              <div class="vTxt">
                男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑
              </div>
              <router-link class="moreLink" id="link1" to="Rwzf"
                >more<span style="color: #999999">+</span></router-link
              >
            </div>
          </li>
          <li class="visit_li">
            <img src="../../assets/imgs/visit.png" alt="" class="visitImg" />
            <div class="visitTxt">
              <router-link class="visitTitle" to="Rwzf"
                >谈宋——男儿志坚写传奇，山泉润水暖桑</router-link
              >
              <div class="vTxt">
                男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑男儿志坚写传奇，山泉润水暖桑
              </div>
              <router-link class="moreLink" id="link1" to="Rwzf"
                >more<span style="color: #999999">+</span></router-link
              >
            </div>
          </li>
        </ul>
        <ul id="demo2"></ul>
      </div>
    </div> -->

    <!-- 会员动态 -->
    <div class="hydt_box">
      <ul class="hydt_ul">
        <!-- 会员动态 -->
        <li class="hydt_li">
          <div class="nTitle">
            <p class="nt1">WORKING</p>
            <p class="nt2">工作安排</p>
          </div>
          <img src="../../assets/imgs/gywm.png" alt="" class="activeImg" />
          <ul>
            <li
              class="active_li"
              v-for="(item, index) in WorkList"
              :key="index"
            >
              <div class="hydtLink" @click="HotRouter(item.id, item.catname)">
                <p>
                  {{ item.title }}
                </p>
                <p class="hydtTime">{{ item.time }}</p>
              </div>
            </li>
          </ul>
          <router-link to="Gzap" class="moreLink" style="margin-left: 88%"
            >more<span style="color: #999999">+</span></router-link
          >
        </li>
        <!-- 红河商情 -->
        <li class="hydt_li" id="hhsq">
          <div class="nTitle">
            <p class="nt1">HONGHE</p>
            <p class="nt2">红河商情</p>
          </div>
          <img src="../../assets/imgs/gywm.png" alt="" class="activeImg" />
          <ul>
            <li
              class="active_li"
              v-for="(item, index) in HhsqList"
              :key="index"
            >
              <div class="hydtLink" @click="HotRouter(item.id, item.catname)">
                <p>
                  {{ item.title }}
                </p>
                <p class="hydtTime">{{ item.time }}</p>
              </div>
            </li>
          </ul>
          <router-link to="Zxtd" class="moreLink" style="margin-left: 88%"
            >more<span style="color: #999999">+</span></router-link
          >
        </li>
        <!-- 湖湘文化 -->
        <li class="hydt_li">
          <div class="nTitle">
            <p class="nt1">CULTURE</p>
            <p class="nt2">湖湘文化</p>
          </div>
          <img src="../../assets/imgs/gywm.png" alt="" class="activeImg" />
          <ul>
            <li
              class="active_li"
              v-for="(item, index) in HxwhList"
              :key="index"
            >
              <div class="hydtLink" @click="HotRouter(item.id, item.catname)">
                <p>
                  {{ item.title }}
                </p>
                <p class="hydtTime">{{ item.time }}</p>
              </div>
            </li>
          </ul>
          <router-link to="Hxwh" class="moreLink" style="margin-left: 88%"
            >more<span style="color: #999999">+</span></router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { rw } from "../../../public/js/rw";

// import autoPlay from "../../js/autoPlay";
const axios = require("axios").default;
export default {
  name: "Index",
  data() {
    return {
      imagesBox:[
        {id:0, idView:require("../../assets/imgs/slideImg4.jpg")},
        {id:1, idView:require("../../assets/imgs/slideImg2.png")},
        {id:2, idView:require("../../assets/imgs/slideImg3.png")},
        {id:3, idView:require("../../assets/imgs/slideImg1.png")}
      ],
      NewsList: [],
      HotList: [],
      WorkList: [],
      HhsqList: [],
      HxwhList: [],
    };
  },
  methods: {
    //商会新闻路由
    NewsRouter(val) {
      this.$router.push({
        path: "/Xwxq",
        query: {
          val: val,
        },
      });
    },
    //热点文章路由
    HotRouter(val, catname) {
      this.$router.push({
        path: "/Hotxq",
        query: {
          val: val,
          catname: catname,
        },
      });
    },
    //日期处理
    TimeDay(vlaue) {
      let Day = vlaue.slice(8, 10);
      return Day;
    },
    //年月处理
    TimeYM(vlaue) {
      let YM = vlaue.slice(0, 7);
      return YM;
    },
    // 去除正文特殊符号
    Texttransfer: function (val) {
      var pattern = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？abcdefghijklmnopqrstuvwxyz1234567890-]"
      );
      var rs = "";
      for (var i = 0; i < val.length; i++) {
        rs = rs + val.substr(i, 1).replace(pattern, "");
      }
      return rs;
    },
  },
  mounted() {
    // rw();
    let _this = this;
    // 商会新闻数据接口
    axios
      .get("http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json", {
        params: {
          size: 3,
          column_id: 12,
        },
      })
      .then(function (response) {
        _this.NewsList = response.data.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    // 热点文章数据接口
    axios
      .get("http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json", {
        params: {
          size: 5,
          order: "a.clicks",
        },
      })
      .then(function (response) {
        _this.HotList = response.data.data;
        console.log(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    //工作安排数据接口
    axios
      .get("http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json", {
        params: {
          size: 4,
          column_id: 14,
        },
      })
      .then(function (response) {
        console.log("工作安排");
        console.log(response.data.data);
        _this.WorkList = response.data.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    //红河商情数据接口
    axios
      .get("http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json", {
        params: {
          size: 4,
          column_id: 21,
        },
      })
      .then(function (response) {
        console.log("红河商情");
        console.log(response.data.data);
        _this.HhsqList = response.data.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    //湖湘文化数据接口
    axios
      .get("http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json", {
        params: {
          size: 4,
          column_id: 22,
        },
      })
      .then(function (response) {
        console.log("湖湘文化");
        console.log(response.data.data);
        _this.HxwhList = response.data.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  },
};
</script>

<style>
.indexContanier {
  font-size: 14px;
}

.el-carousel__item {
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lbImg{
  width: 100%;
  height: 800px;
}

.imgTxt {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 800px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 200;
  color: white;
}

.p2 {
  margin-top: 40px;
}

.ljgd {
  text-decoration: none;
  margin-top: 110px;
  width: 190px;
  height: 45px;
  line-height: 45px;
  color: white;
  font-size: 15px;
  border: 1px solid white;
  border-radius: 50px;
}

.ljgd:hover {
  color: #dc4c3f;
  border: 1px solid #dc4c3f;
}

.navTip {
  width: 100%;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  color: #999999;
}

.tip1 {
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e4e7ed;
}

.tip1_top {
  color: #d96e6d;
  font-size: 23px;
  margin-bottom: 10px;
}

.tipHx {
  color: #999999;
}

.cont {
  display: flex;
}

.contIcon1 {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.ctxt {
  color: #999999;
  display: flex;
  flex-direction: column;
}

.cont1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contIcon2 {
  height: 25px;
  width: 25px;
  margin-bottom: 6px;
}

.shdzLink {
  text-decoration: none;
  color: #999999;
}

.shdzLink:hover {
  color: #dc4c3f;
}

.aboutUs {
  width: 100%;
  padding: 145px 0;
  display: flex;
  justify-content: center;
  background: white;
  background: url(../../assets/imgs/aboutBg.png);
}

.aboutCont {
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.about1 {
  height: 386px;
  display: flex;
}

.aboutImg {
  height: 100%;
  width: 493px;
}

.about1Txt {
  padding: 0 37px;
  color: #999999;
}

.js {
  margin: 50px 0;
  font-size: 20px;
  line-height: 35px;
}

.ckLink {
  text-decoration: none;
  padding: 10px 40px;
  color: #dc4c3f;
  border: 1px solid #dc4c3f;
  border-radius: 50px;
  margin-top: 70px;
}

.about2 {
  margin-top: 50px;
  height: 135px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
}

.a2_li {
  padding: 0 70px;
  border-left: 1px solid #999999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.liTxt {
  color: #999999;
  font-size: 15px;
}

.liTxt0 {
  font-size: 25px;
  color: #767676;
  display: flex;
  align-items: flex-start;
}

/* 商会新闻 */
.news_box {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: #fbfbfb;
}

.news1 {
  width: 760px;
}

.nt1 {
  font-size: 33px;
  font-weight: 300;
  color: #e49291;
}
.nt2 {
  font-size: 25px;
  color: #dc4c3f;
}

.xwxq {
  height: 260px;
  padding: 27px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ed;
}

#lastLi {
  border: none;
}

.xwxqImg {
  height: 100%;
  width: 410px;
  border-radius: 8px;
}

.xwxqTxt {
  margin-left: 10px;
  width: 330px;
  display: flex;
  flex-direction: column;
}

.article {
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 23px 0;
}

#keys1 {
  margin-bottom: 12px;
  width: 330px;
}

.keys {
  color: #767676;
  display: flex;
  justify-content: space-between;
}

.tag {
  width: 70px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #dc4c3f;
  border-radius: 3px;
  color: #dc4c3f;
}

.moreLink {
  text-decoration: none;
  color: #dc4c3f;
}

.news2 {
  margin-left: 20px;
  width: 320px;
}

.hotLi {
  min-width: 310px;
  display: flex;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ed;
}

#keys2 {
  width: 210px;
  margin-top: 8px;
}

.date_box {
  min-width: 85px;
  height: 85px;
  background: #bdbdbd;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hotTxt {
  margin-left: 10px;
  min-width: 220px;
}

.hotLink {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.hotLink:hover {
  color: #dc4c3f;
}

.news3 {
  width: 320px;
}

.memberLi {
  margin-bottom: 10px;
  display: flex;
}

.position {
  font-weight: bold;
}

.visit_box {
  padding: 50px 0;
  background: white;
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
  width: 100%;
}

/* 自动播放 */
#demo {
  margin: 0 auto;
  height: 235px;
  width: 1200px;
  /* border: 1px solid green; */
  overflow: hidden;
}

.visit_ul {
  float: left;
  display: flex;
  justify-content: space-evenly;
}

.visit_li {
  padding-right: 50px;
  height: 235px;
  display: flex;
  justify-content: space-between;
}

.visitImg {
  width: 160px;
  height: 100%;
  border-radius: 10px;
  margin-right: 15px;
}

.visitTxt {
  width: 160px;
}

.visitTitle {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.visitTitle:hover {
  color: #dc4c3f;
}

.vTxt {
  padding: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 115px;
  color: #767676;
  margin-bottom: 55px;
}

.hydt_box {
  padding: 45px 0;
  display: flex;
  justify-content: space-around;
}

.hydt_li {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.activeImg {
  margin-top: 30px;
  width: 100%;
  height: 250px;
  border-radius: 10px;
}

.hydt_ul {
  display: flex;
  justify-content: space-between;
  width: 1200px;
}

.active_li {
  padding: 10px 0;
  border-bottom: 1px solid #bdbdbd;
}

.hydtLink {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #767676;
  cursor: pointer;
}

.hydtLink:hover {
  color: #dc4c3f;
}

#link1 {
  margin-left: 100px;
}

#link2 {
  margin-left: 350px;
}

.tip_p:hover,
.xwxqTxt p:hover {
  color: #dc4c3f;
}
</style>
