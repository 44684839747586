<template>
  <div class="contact_box">
    <!-- <div class="vTitle">{{ data.title }}</div> -->
    <div class="doc_box"></div>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "xq",
  data() {
    return {
      Newscontent: "",
    };
  },
  methods: {
    HTMLEncode(text) {
      let temp = document.querySelector(".doc_box");
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
  mounted() {
    let Id = this.$route.query.val;
    console.log(Id);
    let _this = this;
    let url = "http://hhzhnsh.xp.yn.cn/api/article/details/ids/" + Id;
    axios
      .get(url)
      .then(function (response) {
        let text = response.data.data.content;
        _this.HTMLEncode(text);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  },
};
</script>

<style>
.contact_box {
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.vTitle {
  margin: 0 20px;
  padding-left: 30px;
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  color: #333;
  border-bottom: 1px solid #ebeef5;
}

.doc_box {
  /* max-height: 600px; */
  padding: 30px 30px 70px 30px;
  text-indent: 30px;
  line-height: 35px;
  letter-spacing: 2px;
}

.doc_box img {
  width: 600px;
  height: 400px;
  margin: 15px 300px;
}
</style>