<template>
  <div id="app">
    <!-- 网站Logo -->
    <div class="logo">
      <img src="./assets/imgs/LOGO.png" alt="" />
    </div>
    <!-- 网站tabs -->
    <div class="tabs">
      <div class="tBox">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#ce251e"
          router
          :default-active="activeIndex"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <!-- <el-menu-item index="/Shdt">商会动态</el-menu-item> -->
          <el-submenu router index="/Shdt">
            <template slot="title">商会动态</template>
            <el-menu-item index="/Shdt">商会新闻</el-menu-item>
            <el-menu-item index="/Kcjl">考察交流</el-menu-item>
            <el-menu-item index="/Gzap">工作安排</el-menu-item>
          </el-submenu>

          <el-submenu router index="/Shjs">
            <template slot="title">商会介绍</template>
            <el-menu-item index="/Shjs">商会介绍</el-menu-item>
            <el-menu-item index="/Shwj">商会文件</el-menu-item>
            <el-menu-item index="/Zzjg">组织架构</el-menu-item>
            <el-menu-item index="/Shry">商会荣誉</el-menu-item>
            <el-menu-item index="/Shsp">商会视频</el-menu-item>
            <!-- <el-menu-item index="/Lxwm">联系商会</el-menu-item> -->
          </el-submenu>
          <el-menu-item index="/Rwzf">人物专访</el-menu-item>
          <!-- <el-menu-item index="/Zxtd">咨询天地</el-menu-item> -->
          <el-submenu router index="/Zxtd">
            <template slot="title">咨询天地</template>
            <el-menu-item index="/Zxtd">红河商情</el-menu-item>
            <el-menu-item index="/Hxwh">湖湘文化</el-menu-item>
            <el-menu-item index="/Hnsq">湖南商情</el-menu-item>
            <el-menu-item index="/Cxjs">诚信建设专栏</el-menu-item>
          </el-submenu>

          <el-menu-item index="/Hyfc">会员风采</el-menu-item>
          <el-menu-item index="/Rhsq">入会申请</el-menu-item>
          <el-menu-item index="/Lxwm">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- 路由出口 -->
    <div class="main">
      <router-view />
    </div>
    <hnsh-footer />
  </div>
</template>
<script>
import hnshFooter from "./components/hnshFooter.vue";
export default {
  components: { hnshFooter },
  name: "App",
  data() {
    return {
      activeIndex: String(this.$router.$toaddress),
    };
  },
  mounted() {
    this.$router.push({
      path: "/",
    });
  },
  updated() {
    this.activeIndex = this.$router.$toaddress;
  },
};
</script>

<style scoped>
.el-menu-demo {
  border: none !important;
  display: flex;
}
.el-menu-demo > li {
  width: 100%;
  text-align: center;
}
.tabs {
  position: sticky;
  top: 0px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background: white;
  box-shadow: 0px 15px 10px -15px #ebeef5;
  z-index: 101;
}

.tBox {
  width: 1200px;
}
.logo {
  text-align: center;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo > img {
  width: 280px;
  vertical-align: middle;
}
</style>
