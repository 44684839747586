import Vue from 'vue'
import App from './App.vue'

// 引入ElementPlus
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 使用ElementPlus
Vue.use(ElementUI)



// 引入路由配置
import Router from "./Router.js"

//关闭提示
Vue.config.productionTip = false



new Vue({
  router: Router,
  render: h => h(App),

  beforeCreate() {
    Vue.prototype.$bus = this
  },
  
}).$mount('#app')