// 引入VueRouter
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 引入页面组件
import Index from "./pages/Index/Index"
import Shdt from "./pages/Shdt/Shdt"
import Shjs from "./pages/Shjs/Shjs"
import Rwzf from "./pages/Rwzf/Rwzf"
import Zxtd from "./pages/Zxtd/Zxtd"
import Hyfc from "./pages/Hyfc/Hyfc"
import Rhsq from "./pages/Rhsq/Rhsq"
import Lxwm from "./pages/Lxwm/Lxwm"
//新加
import checkFalse from "./pages/checkFalse/checkFalse"
import checkSuccess from "./pages/checkSuccess/checkSuccess"
import checkIng from "./pages/checkIng/checkIng"
import Shwj from "./pages/Shjs/Shwj"
import Shsp from "./pages/Shjs/Shsp"
import Spxq from "./pages/Shjs/Spxq"
import WjxqTxt from "./pages/Shjs/WjxqTxt"
import WjxqImg from "./pages/Shjs/WjxqImg"
import Zzjg from "./pages/Shjs/Zzjg"
import Shry from "./pages/Shjs/Shry"
import Xwxq from "./pages/Shdt/Xwxq"
import Kcjl from "./pages/Shdt/Kcjl"
import Kcxq from "./pages/Shdt/Kcxq"
import Gzap from "./pages/Shdt/Gzap"
import Gzxq from "./pages/Shdt/Gzxq"
import Hhxq from "./pages/Zxtd/Hhxq"
import Hnsq from "./pages/Zxtd/Hnsq"
import Hnxq from "./pages/Zxtd/Hnxq"
import Hxwh from "./pages/Zxtd/Hxwh"
import Hxxq from "./pages/Zxtd/Hxxq"
import Cxjs from "./pages/Zxtd/Cxjs"
import Hyxq from "./pages/Hyfc/Hyxq"
import Hotxq from "./components/Hotxq"


// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const Router = new VueRouter({
  routes: [{
      path: "/",
      component: Index,
    },
    {
      path: "/Shdt",
      component: Shdt
    }, {
      path: "/Shjs",
      component: Shjs
    }, {
      path: "/Rwzf",
      component: Rwzf
    }, {
      path: "/Zxtd",
      component: Zxtd
    }, {
      path: "/Hyfc",
      component: Hyfc
    }, {
      path: "/Rhsq",
      component: Rhsq
    }, {
      path: "/Lxwm",
      component: Lxwm
    }, {
      path: "/checkFalse",
      component: checkFalse
    }, {
      path: "/checkSuccess",
      component: checkSuccess
    }, {
      path: "/checkIng",
      component: checkIng
    }, {
      path: "/Shwj",
      component: Shwj
    }, {
      path: "/Shsp",
      component: Shsp
    }, {
      path: "/Spxq",
      component: Spxq
    }, {
      path: "/WjxqTxt",
      component: WjxqTxt
    }, {
      path: "/WjxqImg",
      component: WjxqImg
    }, {
      path: "/Zzjg",
      component: Zzjg
    }, {
      path: "/Shry",
      component: Shry
    }, {
      path: "/Xwxq",
      component: Xwxq
    }, {
      path: "/Kcjl",
      component: Kcjl
    }, {
      path: "/Kcxq",
      component: Kcxq
    }, {
      path: "/Gzap",
      component: Gzap
    }, {
      path: "/Gzxq",
      component: Gzxq
    }, {
      path: "/Hhxq",
      component: Hhxq
    }, {
      path: "/Hnsq",
      component: Hnsq
    }, {
      path: "/Hnxq",
      component: Hnxq
    }, {
      path: "/Hxwh",
      component: Hxwh
    }, {
      path: "/Hxxq",
      component: Hxxq
    }, {
      path: "/Cxjs",
      component: Cxjs
    }, {
      path: "/Hyxq",
      component: Hyxq
    },
    {
      path: "/Hotxq",
      component: Hotxq
    }
  ]
})

// 切换路由时，回到页面顶部
Router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next()
  Router.$toaddress = to.path
  console.log(Router.$toaddress)
})

export default Router