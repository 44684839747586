<template>
  <div class="zhu">
    <!-- <img src="../../assets/imgs/banner_shdt.png" alt="" class="banner" /> -->
    <div class="sz">
      <div class="p">
        <!-- <el-carousel height="600px" :interval="4000">
          <el-carousel-item v-for="item in 2" :key="item"> </el-carousel-item>
        </el-carousel> -->
        <img :src="url + InfoObj.prize_images" alt="" style="width: 100%" />
      </div>
      <div class="wz">
        <div class="jcxx">基础信息</div>
      </div>
      <div class="jcxx1">
        <div class="xq">
          <div class="xq-1">
            <div style="width: 200px; background: #ce251f">
              <p class="p1">企业全称</p>
            </div>
            <div style="width: 360px; background: #ededed">
              <p class="p-2">{{ InfoObj.company_name }}</p>
            </div>
          </div>
          <div class="xq-1">
            <div style="width: 200px; background: #ce251f">
              <p class="p1">企业类型</p>
            </div>
            <div style="width: 360px; background: #ededed">
              <p class="p-2">{{ InfoObj.title }}</p>
            </div>
          </div>
        </div>
        <div class="xq">
          <div class="xq-1">
            <div style="width: 200px; background: #ce251f">
              <p class="p1">信用代码</p>
            </div>
            <div style="width: 360px; background: #ededed">
              <p class="p-2">{{ InfoObj.credit_code }}</p>
            </div>
          </div>
          <div class="xq-1">
            <div style="width: 200px; background: #ce251f">
              <p class="p1">法人代表</p>
            </div>
            <div style="width: 360px; background: #ededed">
              <p class="p-2">{{ InfoObj.legal_person }}</p>
            </div>
          </div>
        </div>
        <div class="xq">
          <div class="xq-1">
            <div style="width: 200px; background: #ce251f">
              <p class="p1">注册时间</p>
            </div>
            <div style="width: 360px; background: #ededed">
              <p class="p-2">{{ InfoObj.createtime }}</p>
            </div>
          </div>
          <div class="xq-1">
            <div style="width: 200px; background: #ce251f">
              <p class="p1">联系电话</p>
            </div>
            <div style="width: 360px; background: #ededed">
              <p class="p-2">{{ InfoObj.tel }}</p>
            </div>
          </div>
        </div>
        <div class="xq">
          <div style="width: 200px; background: #ce251f">
            <p class="p1">公司地址</p>
          </div>
          <div style="width: 1000px; background: #ededed">
            <p class="p-2" style="text-align: left; padding: 0 20px">
              {{ InfoObj.address }}
            </p>
          </div>
        </div>
        <div class="xq2">
          <div style="width: 200px; background: #ce251f">
            <p class="p1">经营范围</p>
          </div>
          <div style="width: 1000px; background: #ededed">
            <p class="pRange">
              {{ InfoObj.business }}
            </p>
          </div>
        </div>
      </div>
      <div class="wz">
        <div
          class="jcxx"
          style="margin-top: 25px; margin-bottom: 25px !important"
        >
          公司产品
        </div>
      </div>
      <div class="gscp">
        <div class="gscp1">
          <div class="cp" v-for="(item, index) in cpArrImg" :key="index">
            <img :src="url + item" style="width: 370px; height: 220px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let axios = require("axios").default;
export default {
  name: "Hyxq",
  data() {
    return {
      InfoObj: "",
      cpArrImg: "",
      url: "http://hhzhnsh.xp.yn.cn",
    };
  },
  methods: {},
  mounted() {
    let Id = this.$route.query.val;
    let _this = this;
    axios
      .get(
        "http://hhzhnsh.xp.yn.cn/mobile/member/find_member_details/ids/" + Id
      )
      .then(function (response) {
        let InfoObj = response.data.data;
        _this.InfoObj = InfoObj;
        let Str = InfoObj.product;
        let Arr = Array(Str.split(","));
        _this.cpArrImg = Arr[0];
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.gscp1 > .cp:nth-child(3n) {
  margin-right: 0;
}
a {
  text-decoration: none;
}

.zhu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding-bottom: 30px;
}

.tu {
  width: 100%;
  height: 315px;
}

.sz {
  margin-top: 5px;
  width: 1200px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.p {
  background: #ffffff;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.xq {
  margin: 16px 0px 16px 0px;
}

.jcxx {
  font-size: 20px;
  border-bottom: 5px red solid;
  font-weight: bold;
  width: 100px;
  margin-bottom: 15px;
}

.jcxx1 {
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.xq {
  width: 1200px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}

.xq2 {
  width: 1200px;
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 60px;
}

.xq-1 {
  width: 560px;
  height: 100%;
  display: flex;
}

.p1 {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
}

.p-2 {
  text-align: center;
  color: #707070;
  font-size: 18px;
}

.pRange {
  color: #707070;
  font-size: 18px;
  padding: 0 20px;
}

.gscp {
  width: 1200px;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gscp1 {
  height: 270px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.cp {
  width: 370px;
  height: 270px;
  margin-right: 45px;
}
</style>
