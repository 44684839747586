<template>
  <div class="container">
    <img src="../../assets/imgs/banner_zxtd.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p01">HONGHE</p>
        <p class="p02">红河商情</p>
      </div>
      <xq />
    </div>
     
  </div>
</template>

<script>
import xq from "../../components/xq.vue"
export default {
    components: { xq },
    name: "Hhxq",

}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}



</style>