<template>
  <div>
    <div class="fImg">
      <p>
        团结、拓展、交流、服务、共赢、弘扬传承湖湘文化，团结在滇湘籍工商业界人士
      </p>
    </div>
    <div class="main">
      <ul class="footer_ul">
        <li class="footer_li" id="f_li1">
          <div class="li1_box">
            <h3 class="title">网站导航</h3>
            <div class="hx"></div>
            <ul class="wldh">
              <div class="wldh1">
                <router-link class="wldh_li" id="liFirst" to="/">商会首页</router-link>
                <router-link class="wldh_li" to="/Shdt">商会动态</router-link>
                <router-link class="wldh_li" to="/Shjs">商会介绍</router-link>
                <router-link class="wldh_li" to="/Rwzf">人物专访</router-link>
              </div>
              <div class="wldh1">
                <router-link class="wldh_li" id="liFirst" to="/Zxtd">咨询天地</router-link>
                <router-link class="wldh_li" to="/Hyfc">会员风采</router-link>
                <router-link class="wldh_li" to="/Rhsq">入会申请</router-link>
                <router-link class="wldh_li" to="/Lxwm">联系我们</router-link>
              </div>
            </ul>
            <h3 class="title" id="yqlink">友情链接</h3>
            <div class="hx"></div>
            <ul class="yqlj">
              <div class="wldh1">
                <el-link type="info" 
                class="yqlink_li" 
                id="liFirst" 
                :underline="false"
                href="http://www.yn.gov.cn/">
                  云南省人民政府
                </el-link>
                <el-link type="info" 
                class="yqlink_li" 
                id="liFirst" 
                :underline="false"
                href="http://www.hh.gov.cn/">
                  红河州人民政府
                </el-link>
                <el-link type="info" 
                class="yqlink_li" 
                id="liFirst" 
                :underline="false"
                href="http://www.hunan.gov.cn/">
                  湖南省人民政府
                </el-link>
              </div>
            </ul>
          </div>
        </li>
        <li class="footer_li" id="f_li2">
          <div class="li2_box">
            <h3 class="title" style="mar">联系我们</h3>
            <div class="hx"></div>
            <div class="li2_cont">
              <div class="cont" id="cont1">
                <img src="../assets/imgs/phone.png" alt="" class="contIcon" />
                <div class="ctxt">
                  <p style="font-weight: bold">15012163225</p>
                  <p>周一至周五&nbsp;9:00~18:00</p>
                </div>
              </div>
              <div class="cont">
                <img src="../assets/imgs/QQIcon.png" alt="" class="contIcon" />
                <div class="ctxt">
                  <p style="font-weight: bold">QQ在线咨询</p>
                  <p>24小时为您服务</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="footer_li" id="f_li3">
          <div class="li2_box">
            <h3 class="title" style="mar">关注我们</h3>
            <div class="hx"></div>
            <div class="li2_cont">
              <img src="../assets/imgs/erWeiMa.jpg" alt="" class="erCode" />
              <!-- <img src="" alt="" class="erCode" /> -->
            </div>
          </div>
        </li>
      </ul>
      <div class="copyright">
        ©copyright&nbsp;2012-2025&nbsp;版权所有&nbsp;红河州湖南商会版权声明&nbsp;滇ICP备16004478号&nbsp;All&nbsp;right&nbsp;reserved&nbsp;&nbsp;&nbsp;&nbsp;技术支持：腾亿互联
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hnshFooter",
};
</script>

<style scoped>
.main > ul > li :nth-child(1) > h3 {
  color: white;
}

.main {
  height: 332px;
  background: #292e32;
  min-width: 1200px;
}
.fImg > p {
  font-weight: bold;
  text-align: center;
  color: white;
  font-size: 30px;
}

.fImg {
  min-width: 1200px;
  height: 217px;
  background: url(../assets/imgs/banner_footer.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_ul {
  height: 284px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #3c4145;
}

.footer_li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#f_li2 {
  padding-left: 80px;
  padding-right: 80px;
  border-left: 1px solid #3c4145;
  border-right: 1px solid #3c4145;
}

.title {
  color: white;
}

.hx {
  width: 30px;
  margin-top: 8px;
  border-bottom: 2px solid white;
}

.wldh {
  display: flex;
  flex-direction: column;
}

.wldh1 {
  display: flex;
  margin-top: 20px;
}

.wldh_li,
.yqlink_li {
  height: 15px;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #999999;
  border-left: 1px solid #999999;
  text-decoration: none;
}

#liFirst {
  border: none;
}

.wldh_li {
  width: 120px;
}

.yqlink_li {
  width: 150px;
}

#yqlink {
  margin-top: 40px;
}

.li2_cont {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
}

.cont {
  display: flex;
}

#cont1 {
  margin-right: 60px;
}

.contIcon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.ctxt {
  color: #999999;
  display: flex;
  flex-direction: column;
}

/* 二维码 */
.erCode {
  width: 90px;
  height: 90px;
  margin-right: 20px;
}

.copyright {
  height: 48px;
  line-height: 35px;
  font-size: 13px;
  text-align: center;
  padding: 10px 0;
  background: #292e32;
  color: #999999;
}
</style>    