<template>
  <div class="container">
    <img src="../../assets/imgs/banner_shdt.png" alt="" class="banner" />
    <div class="main">
      <div class="mainHeader">
        <div class="title">
          <p class="p01">HONGHE</p>
          <p class="p02">会员列表</p>
        </div>

        <!-- <div class="nav">
          <p class="navP1">企业会员</p>
          <p class="navP2">个人会员</p>
        </div> -->

        <div class="input_box">
          <el-input
            class="search_input"
            placeholder="请输入关键字"
            v-model="input"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
      <div class="mainCont">
        <div class="cont_box">
          <ul class="list_box">
            <li v-for="(item, index) in ArrList" :key="index">
              <img :src="url + item.user_avatar" alt="" class="corporate_img" />
              <div class="corporateInfo">
                <div class="memberName" to="Hyxq" @click="Hyfcroute(item.id)">
                  {{ item.company_name }}
                </div>
                <div class="keywords">
                  <p>{{ item.title }}</p>
                  <p>{{ item.legal_person }}</p>
                </div>
                <p class="address">地址:{{ item.address }}</p>
                <p class="address">经营范围:{{ item.business }}</p>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="page_box">
          <el-pagination background layout="prev, pager, next" :total="1000">
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
let axios = require("axios").default;
export default {
  name: "Hyfc",
  data() {
    return {
      input: "",
      ArrList: [],
      url: "http://hhzhnsh.xp.yn.cn/",
    };
  },
  methods: {
    Hyfcroute(val) {
      this.$router.push({
        path: "/Hyxq",
        query: {
          val: val,
        },
      });
    },
  },
  mounted() {
    let _this = this;
    axios
      .get("http://hhzhnsh.xp.yn.cn/mobile/member/fetch_member_json")
      .then(function (response) {
        console.log(response.data.data);
        _this.ArrList = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.container {
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.banner {
  background: #2e2e2e;
  height: 315px;
  width: 100%;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainHeader {
  padding-bottom: 17px;
  width: 1200px;
  height: 140px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.nav {
  margin-left: 257px;
  margin-right: 70px;
  width: 330px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  border: 1px solid #ce251e;
  display: flex;
  justify-content: space-between;
}

.navP1 {
  width: 163px;
  color: white;
  background: #ce251e;
}

.navP2 {
  width: 160px;
  color: #ce251e;
}

.input_box {
  width: 420px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.search_input {
  border: none;
}

.el-icon-search {
  color: #ce251e;
}

.mainCont {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cont_box {
  margin: 45px 0;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.list_box {
  list-style: none;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list_box li {
  height: 177px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  margin-bottom: 39px;
  padding-right: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.corporate_img {
  width: 268px;
  height: 100%;
  background: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.corporateInfo {
  width: 245px;
  font-size: 12px;
  color: #333333;
  display: flex;
  flex-direction: column;
  height: 177px;
  justify-content: space-evenly;
  padding: 25px 10px;
}

.rLink {
  text-decoration: none;
  color: #333333;
  font-size: 11px;
  padding-left: 5px;
  margin: 10px 0 20px 210px;
  border-left: 2px solid #ce190a;
}

.memberName {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #ce190a;
  overflow: hidden;
  /*将对象作为弹性伸缩盒子模型显示*/
  display: -webkit-box;
  /*设置子元素排列方式*/
  -webkit-box-orient: vertical;
  /*设置显示的行数，多出的部分会显示为...*/
  -webkit-line-clamp: 1;
  cursor: pointer;
}

.keywords {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}

.address {
  width: 240px;
  margin-top: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_box {
  margin-bottom: 40px;
}
</style>
