<template>
  <div class="container">
    <img src="../../assets/imgs/banner_zxtd.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p01">FAITH</p>
        <p class="p02">诚信建设专栏</p>
      </div>
      <ul class="contact_box">
        <p style="text-align: center; padding: 30px; color: #999" v-if="kong">
          暂无数据
        </p>
        <li v-for="(item, index) in NewsList" :key="index">
          <div class="txt">
            <img src="../../assets/imgs/dian.png" alt="" class="tagImg" />
            <div class="rLink" @click="HotRouter(item.id, item.catname)">
              {{ item.title }}
            </div>
          </div>
          <div class="date">{{ item.time }}</div>
        </li>
      </ul>
    </div>
     
  </div>
</template>


<script>
import axios from 'axios'

export default {
    name: "Cxjs",
    data(){
      return{
        NewsList: [],
        kong: false,
      }
    },

    mounted(){
      let that = this;
      axios.get( 'http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json', {
        params: {
          column_id: 24,
        }
      })
      .then( Response => {
        that.NewsList = Response.data.data;
        console.log(that.NewsList)
      })
      .catch( Error => {
        console.log(Error);
      })
    },

    methods:{
      HotRouter(val, catname){
        this.$router.push({
          path: "/Hotxq",
          query: {
            val: val,
            catname: catname,
          },
        });
      },
    }    

}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 130px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.contact_box {
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.contact_box li{
    margin: 0 20px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    border-bottom: 1px solid #EBEEF5;
    cursor: pointer;
}

.txt{
    display: flex;
    align-items: center;
}

.tagImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 1px solid blue; */
}

.rLink{
    font-size: 17px;
    color: #333;
    text-decoration: none;
}

.rLink:hover{
    color: #d9625e;
}


#lastLi{
    border: none;
}
</style>