<template>
  <el-upload
    action="http://hhzhnsh.xp.yn.cn/api/common/upload"
    :on-success="onSuccess"
    :file-list="fileList"
    :auto-upload="true"
    list-type="picture"
  >
    <el-button size="small" type="success" @click="btn">点击上传</el-button>
  </el-upload>
</template>

<script>
export default {
  name: "yyzz",
  data() {
    return {
      yyzzList: [],
      fileList: []
    };
  },
  methods: {
    btn() {
      
    },
    onSuccess(response, file, fileList) {
      let xx = response.data.url;
      this.yyzzList.push(xx)
      this.$bus.bus_lic_image = this.yyzzList;
      
    },
  },
};
</script>

<style>
</style>