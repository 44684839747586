<template>
  <el-upload
    action="http://hhzhnsh.xp.yn.cn/api/common/upload"
    :on-success="onSuccess"
    :file-list="fileList"
    :auto-upload="true"
    list-type="picture"
  >
    <el-button size="small" type="success" @click="btn">点击上传</el-button>
  </el-upload>
</template>

<script>
export default {
  name: "qycp",
  data() {
    return {
      qycpList: [],
      fileList: [],
      
    };
  },
  methods: {
    btn() {
      
    },
    onSuccess(response, file, fileList) {
      let imgURL = response.data.url;
      this.qycpList.push(imgURL);
      this.$bus.product = this.qycpList;

      /**console.log(response);
      console.log("---");
      console.log(file);
      console.log("---");
      console.log(fileList); **/
    },
  },
};
</script>

<style>
</style>