<template>
  <div class="zhu">
    <img src="../../assets/imgs/banner_rwzf.png" alt="" class="tu" />
    <div class="sz">
      <div class="p">
        <p class="p01">VISIT</p>
        <p class="p02">人物专访</p>
      </div>
      <p style="text-align: center; padding: 30px; color: #999" v-if="kong">
        暂无数据
      </p>
      <div class="wz">
        <div class="kj" to="Gzxq" v-for="(item, index) in List" :key="index" @click="HotRouter(item.id, item.catname)">
          <div class="tp">
            <img :src="'http://hhzhnsh.xp.yn.cn/' + item.cover_image" alt="" class="tu1" />
          </div>
          <div class="wz1">
            <div>
              <p class="wzp">
                {{ item.title }}
              </p>
            </div>
            <div class="doc_box" id="aConent">
                {{ item.content }}
            </div>
            <div class="wzp1">
              <div class="wzp1p"><p class="wzp1p1">人物专访</p></div>
              <div><p class="rq">日期：2021-09-23</p></div>
              <div><p class="rq">点击：555</p></div>
            </div>
            <div class="wzp1">
              <div><a href="" class="wza">more+</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Rwzf",
  data() {
    return {
      kong: false,
      List: "",
    };
  },

  mounted() {
    let _this = this;
    // 数据请求接口
    axios
      .get("http://hhzhnsh.xp.yn.cn/api/article/fetch_article_json", {
        params: {
          column_id: 19,
        },
      })
      .then(function (response) {
        if (response.data.data.length === 0) {
          _this.kong = true;
        }
        _this.List = response.data.data;
        console.log(_this.List);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  },
  methods: {
    HTMLEncode(text) {
      let temp = document.querySelector(".doc_box");
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    
    },

    //热点文章路由
    HotRouter(val, catname) {
      this.$router.push({
        path: "/Hotxq",
        query: {
          val: val,
          catname: catname,
        },
      });
    },
  },
};
</script>

<style scoped>
.zhu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 30px;
}
.tu {
  width: 100%;
  height: 315px;
}
.sz {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}
.p {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}
.wz {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tp {
  height: 330px;
  widows: 520px;
}
.tu1 {
  height: 330px;
  widows: 520px;
  border-radius: 5px;
  cursor: pointer;
}
.wz1 {
  height: 330px;
  widows: 520px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.kj {
  text-decoration: none;
  width: 1040px;
  display: flex;
  margin: 30px;
}
.wzp {
  color: #333;
  margin-left: 20px;
  font-size: 21.2px;
}
.wzp1 {
  margin: 20px;
  font-size: 18px;
  color: #999;
  display: flex;
  justify-content: space-between;
}
.wzp1p {
  width: 88px;
  height: 33px;
  color: #d65051;
  border: #d65051 solid 1px;
  text-align: center;
  border-radius: 5px;
}
.wzp1p1 {
  margin-top: 2px;
}
.rq {
  color: black;
}
.wza {
  color: #d65051;
  font-size: 20px;
  text-decoration: none;
  position: absolute;
  margin-top: 10px;
}

#aConent {
  max-height: 300px;
  letter-spacing: 2px;
  /* border: 1px solid blue; */
  color: #999;
}

</style>