<template>
  <div class="zhu">
    <img src="../../assets/imgs/banner.png" alt="" class="tu" />
    <div class="sz">
      <div class="p">
        <p class="p01">INTRODUCE</p>
        <p class="p02">商会简介</p>
      </div>
      <div class="wz">
        <div class="wz1">
          <div class="dian"></div>
          <p class="wzp">
            海南省湖南商会是由2007年成立的湘琼区域合作促进会转型而来，2009年在促进会基础上加挂了湖南商会牌子，2012
            年8月第二届湖南商会换届后，注销了促进会名称，正式转型为海南省湖南商会。我会在湘琼两省党委、政府的领导下，
            在海南省民政厅、海南省工商联、商务厅、湖南省工商联、商务厅、湖南异地商会联合会的正确指导下，在湖南省驻海
            南办事处的鼎力支持下，在所有商会理事会成员的共同努力下，按照商会章程和“团结、拓展、交流、服务、共赢”的办会理念，商会取得了骄人的成绩和长足的发展。
          </p>
        </div>
        <div class="wz1">
          <div class="dian"></div>
          <p class="wzp">
            2019年9月商会成功选举了第四届理事会，海口经济学院董事长曹成杰连任第四届会长。商会积极完善自身组织建设，
            成立了办公室、会员服务部、法律维权部、文艺宣传部，三亚联络办，并设立青年创业联谊会;随着国家对商协会的发
            展越来越重视，对商协会的审批等程序也逐渐简化，我们商会也顺应新形式，改变办会思路，鼓励扶植条件成熟的市县成立商会，把省级商会办成高效精干的商会，这样能更好地团结市县商会，共同促进在琼湘商的发展，目前已经成立了邵阳商会、衡阳商会、永州商会、常德商会、隆回商会、安乡商会、平江商会、宁乡商会、琼海湖南商会等，同时在条件成熟的地方我们也发展办事处，如三亚办事处、东方办事处、播州办事处(筹)。在调整办会思路后，商会很好地团结了各市县商会及二级机构，直接影响的企业超过1000家，商会影响力与凝聚力都与日俱增
          </p>
        </div>
        <div class="wz1">
          <div class="dian"></div>
          <p class="wzp">
            商会会员主要分布于房地产、建筑、教育、环保、制造业、贸易聘领域，会员资产总额超300亿元。我商会险积极促进
            湘企在海南做大做强，还积极响应湖南省委省政府“迎老乡，回故乡、建家乡”的号召，引导会员企业回湖南投资兴业
            回报家乡，据不完全统计，会员企业近年累计回乡投资超过55亿元，已完成投资超过28亿元。
          </p>
        </div>
        <div class="wz1">
          <div class="dian"></div>
          <p class="wzp">
            为表影商会贡献，2019年10月荣获湖南省人力资源和社会保障厅，湖南省工商业联合会颁发的2018-2019年湖南异地商会“兴湘贡献奖”荣誉称号:2019年3月荣获全国工商联颁发四好商会”荣借称号:2017年湖南省人民政府授予商会兴湘贡献奖”荣誉称号，2017年被湖南省工商联表影为“湖南商协会区域合作优秀单位”，并在商会设立“全国湖南商会联席会议联络办公室:2018年12月荣获湖南省工商联“抗洪救灾爱心商会”荣誉称号等。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Shjs",
};
</script>

<style scoped>
.zhu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 30px;
}
.tu {
  width: 100%;
  height: 315px;
}
.sz {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}
.p {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}
.wz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}
.wz1 {
  display: flex;
  margin: 30px;
  font-size: 18px;
  
}
.dian {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #d02722;
  margin: 14px;
}
.wzp {
  color: #555;
  width: 1100px;
  line-height: 1.8;
}
</style>