<template>
  <div class="container">
    <img src="../../assets/imgs/banner_lxwm.png" alt="" class="banner">
    <div class="main">
      <div class="title">
        <p class="p01">DOWNLOAD</p>
        <p class="p02">联系方式</p>
      </div>
      <div class="contact_box">
        <div class="info">
          <ul class="infoLeft">
            <li class="info_li">
              <img src="../../assets/imgs/jianzhu.png" alt="" class="liIcon">
              <div class="txt">
                <p class="address">云南省红河州蒙自市双河小区余庆街28号私家一栋</p>
              </div>
            </li>
            <li class="info_li">
              <img src="../../assets/imgs/erji.png" alt="" class="liIcon">
              <div class="txt" id="phone">
                <p>15012163225</p>
              </div>
            </li>
            <li class="info_li">
              <img src="../../assets/imgs/youjian.png" alt="" class="liIcon">
              <div class="txt" id="phone">
                <p>a350528596@163.com</p>
              </div>
            </li>
          </ul>
          <div class="infoRight">
            <img src="../../assets/imgs/erWeiMa.jpg" alt="" class="ercode" />
            <p>微信公众号</p>
          </div>
        </div>
        <div class="map">
          <img src="../../assets/imgs/map.jpg" alt="" class="mapImg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lxwm",
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F3F3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.contact_box {
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoLeft {
  list-style: none;
}

.info_li {
  display: flex;
}

.liIcon {
  width: 23px;
  height: 23px;
}

.txt {
  margin-left: 25px;
  margin-bottom: 15px;
}

.address {
  width: 300px;
  line-height: 26px;
}

.infoRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ercode {
  width: 140px;
  height: 140px;
  margin-bottom: 15px;
}

.mapImg {
  margin-top: 50px;
  height: 430px;
  width: 750px;
  border-radius: 10px;
}
</style>