<template>
  <div class="container">
    <img src="../assets/imgs/banner_shdt.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p02">{{ $route.query.catname }}</p>
      </div>
      <div class="contact_box">
        <div class="doc_box"></div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Hotxq",
  data() {
    return {};
  },
  methods: {
    HTMLEncode(text) {
      let temp = document.querySelector(".doc_box");
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
  mounted() {
    let Id = this.$route.query.val;
    console.log(Id);
    let _this = this;
    let url = "http://hhzhnsh.xp.yn.cn/api/article/details/ids/" + Id;
    axios
      .get(url)
      .then(function (response) {
        let text = response.data.data.content;
        _this.HTMLEncode(text);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}
.contact_box {
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.vTitle {
  margin: 0 20px;
  padding-left: 30px;
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  color: #333;
  border-bottom: 1px solid #ebeef5;
}

.doc_box {
  /* max-height: 600px; */
  padding: 30px 30px 70px 30px;
  text-indent: 30px;
  line-height: 35px;
  letter-spacing: 2px;
}

.doc_box img {
  width: 600px;
  height: 400px;
  margin: 15px 300px;
}
</style>