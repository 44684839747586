<template>
  <div class="container">
    <img src="../../assets/imgs/banner_shdt.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p01">FORM</p>
        <p class="p02">入会表单</p>
      </div>
      <div class="contact_box">
        <el-form ref="form" :model="form" label-width="100px">
          <!-- 下拉框 -->
          <el-form-item label="入会申请类型">
            <el-select v-model="form.position" placeholder="请选择申请职务">
              <el-option 
              v-for="(item, index) in form.positionList"
              :key="index"
              :value="item.id"
              :label="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="企业名称">
            <el-input
              v-model="form.corporateName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="信用代码">
            <el-input
              v-model="form.corporateCode"
              placeholder=" "
            ></el-input>
          </el-form-item>
          <el-form-item label="法人">
            <el-input
              v-model="form.manager"
              placeholder="请输入法人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人身份证">
            <el-input
              v-model="form.managerId"
              placeholder="请输入法人身份证号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="入会联系人">
            <el-input
              v-model="form.rePeople"
              placeholder="请输入入会联系人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input
              v-model="form.phone"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业标志">
            <qybz></qybz>
          </el-form-item>
          <el-form-item label="企业地址">
            <el-cascader
              style="width: 100%"
              :options="form.options"
              v-model="form.selectedOptions"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="企业风采">
            <hqjx></hqjx>
          </el-form-item>
          <el-form-item label="企业产品">
            <qycp></qycp>
          </el-form-item>
          <el-form-item label="营业执照" class="yyzz">
            <yyzz></yyzz>
          </el-form-item>
          <el-form-item label="主营业务">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>

          <!-- <el-form-item label="入会时间">
            <el-input
              v-model="form.sqDate"
              placeholder=""
            ></el-input>
          </el-form-item> -->

          <el-form-item style="display: flex; justify-content: center;">
            <el-button type="primary" style="width: 300px;" @click="saveForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script type="module">
const axios = require("axios").default

import { regionData } from "element-china-area-data";
import yyzz from "./yyzz.vue";
import hqjx from "./hqjx.vue";
import qycp from "./qycp.vue";   //企业产品照片
import qybz from "./qybz.vue";    //企业标志照片

export default {
  components: { yyzz, hqjx, qycp, qybz },
  name: "Rhsq",
  data() {
    return {
      form: {
        // 入会申请类型
        position: "",
        positionList: "",
        value: "",
        corporateName: "",         //企业名称
        corporateCode: " ",       //信用代码
        manager: "",             //法人
        managerId: "",           //法人身份证
        rePeople: "",            //入会联系人
        phone: "",              //联系电话
        sqDate: "",         //申请时间
        
        //企业地址，原籍贯
        options: regionData,
        selectedOptions: [],

        //营业执照       
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        desc: "",

      },
    };
  },

  mounted(){
    let that = this;
    //入会申请类型数据接口
    axios.get('http://hhzhnsh.xp.yn.cn/api/index/fetch_type_list')   //要改接口
    .then( Response => {
      that.form.positionList = Response.data.data;
      console.log(that.form.positionList);
    })
    .catch( Error => {
      console.log(Error)
    })


  },

  methods: {
    //表单提交
    saveForm(){
      let that = this;
      axios.post('http://hhzhnsh.xp.yn.cn/api/index/add', {
        type_id: that.form.position,     //入会申请类型
        company_name: that.form.corporateName,         //企业名称
        credit_code: that.form.corporateCode,       //信用代码        
        legal_person: that.form.manager,             //法人
        legal_person_card: that.form.managerId,           //法人身份证
        real_name: that.form.rePeople,            //入会联系人
        tel: that.form.phone,              //联系电话
        business: that.form.desc,          //主营业务
        address: that.form.selectedOptions,          //地址
        bus_lic_image:  that.imgPass(that.$bus.bus_lic_image),    //营业执照   
        product:        that.imgPass(that.$bus.product),    //企业产品
        prize_images:   that.imgPass(that.$bus.prize_images),    //企业奖项
        //差企业标志 
        user_avatar: that.imgPass(this.$bus.maker)     

      })
      .then( Response => {

        let msg = Response.data.msg;
        if(Response.data.code === 0){
          this.$message.error(msg);
        }
        else if(Response.data.code === 1){
          this.$router.push("checkIng")
        }
      })
      .catch( Error => {
        console.log(Error)
      })
    },

        //判断图片是否为空
    imgPass(arr){
      console.log("图片长度：", arr);
      if(arr === undefined){
          return null
      }else if(arr.length>=1){
          return arr.join(",")
      }
    },

  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.contact_box {
  margin-top: 85px;
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upHonorImg {
  width: 500px;
}

/* 营业执照 */
.avatar-uploader {
  width: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}

.el-form-item{
  width: 550px;
}

</style>