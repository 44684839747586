<template>
  <div class="container">
    <img src="../../assets/imgs/banner_shjj.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p01">ORGANISATION</p>
        <p class="p02">组织架构</p>
      </div>
      <div class="contact_box">
          <div class="vTitle">红河州湖南商会组织构成</div>
          <div class="doc_box">
              <img src="../../assets/imgs/zzjj.png" alt="">
          </div>
      </div>
    </div>
     
  </div>
</template>

<script>
export default {
    name: "Zzjg",

}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.contact_box {
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.vTitle{
    margin: 0 20px;
    padding-left: 30px;
    height: 70px;
    line-height: 70px;
    color: #333;
    border-bottom: 1px solid #EBEEF5;
}

.doc_box{
    /* border: 1px solid black;  */
    max-height: 900px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.doc_box::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}

.doc_box img{
    width: 600px;
    /* border: 1px solid black;  */
}

</style>