<template>
  <div class="container">
    <img src="../../assets/imgs/banner_shjj.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p01">HONORS</p>
        <p class="p02">商会荣誉</p>
      </div>
      <ul class="contact_box">
          <li>
            <p class="vTitle">2019年3月荣获全国工商联颁发“四好商会”荣誉称号 </p>
            <img src="" alt="">
          </li>
          <li>
            <p class="vTitle">2019年3月荣获全国工商联颁发“四好商会”荣誉称号 </p>
            <img src="" alt="">
          </li>
      </ul>
    </div>
     
  </div>
</template>

<script>
export default {
    name: "Shry",

}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.contact_box {
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
    overflow-y: auto;
    padding: 20px 0;
}

.contact_box::-webkit-scrollbar{
    width: 5px;
    height: 5px;
}

.vTitle{
    margin: 10px 0;
    font-size: 18px;
    font-weight: normal;
    color: #606266;
}

.contact_box img{
    width: 900px;
    height: 500px;
    /* border: 1px solid black;  */
}

</style>