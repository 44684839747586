<template>
  <div class="container">
    <img src="../../assets/imgs/banner_shjj.png" alt="" class="banner" />
    <div class="main">
      <div class="title">
        <p class="p01">VIDEOS</p>
        <p class="p02">商会视频</p>
      </div>
      <div class="contact_box">
          <div class="vTitle">商会宣传视频</div>
          <div class="video_box">
            <video controls="controls">
                <!-- <source src="movie.ogg" type="video/ogg" /> -->
                <!-- <source src="../../assets/imgs/video.mp4" type="video/mp4" /> -->
                Your browser does not support the video tag.
            </video>          
          </div>
      </div>
    </div>
     
  </div>
</template>

<script>
export default {
    name: "Spxq",

}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 315px;
}

.main {
  margin-top: 60px;
  width: 1200px;
  background: white;
  display: flex;
  flex-direction: column;
}

.title {
  background: #f3f3f3;
  padding-bottom: 20px;
}

.p01 {
  font-size: 25px;
  font-weight: 300;
  color: #e1a2a1;
}

.p02 {
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #d9625e;
  font-weight: bold;
  border-bottom: 2px solid #d9625e;
}

.contact_box {
  color: #606266;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.vTitle{
    margin: 0 20px;
    padding-left: 30px;
    height: 70px;
    line-height: 70px;
    color: #333;
    border-bottom: 1px solid #EBEEF5;
}

.video_box{
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 350px;
    padding-bottom: 50px;
}

.video_box video{
    width: 500px;
    height: 400px;
    margin-top: -20px;
}
</style>