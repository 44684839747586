<template>
  <el-upload
    action="http://hhzhnsh.xp.yn.cn/api/common/upload"
    :on-success="onSuccess"
    :file-list="fileList"
    :auto-upload="true"
    list-type="picture"
  >
    <el-button size="small" type="success" @click="btn">点击上传</el-button>
  </el-upload>
</template>

<script>
export default {
  // 企业标志
  name: "qybz",
  data() {
    return {
      fileList: [],
      qybzList: []
    };
  },
  methods: {
    btn() {
      console.log(this.fileList);
    },
    onSuccess(response, file, fileList) {
      let imgURL = response.data.url;
      this.qybzList.push(imgURL);
      this.$bus.maker = this.qybzList;

    },
  },
};
</script>

<style>
</style>